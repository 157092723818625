$info-panel-width: 342px;

:root {
	--infopanel-width: 342px;
	--nav-width: 235px;
	--top-panel-height: 109px;
	--top-panel-split-height: 134px;
}

@media screen and (max-width: 992px) {
	:root {
		--infopanel-width: 320px;
	}
	.squeezed .dash-row div[class*="col-"] {
		width: 100%;
	}
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
	.dash-row div[class*="col-"] {
		width: 100%;
	}
}

@media only screen and (max-width: 880px) {
	.admin-spacing .search-bar {
		width: 25vw !important;
	}
	.top-row.top-row-split .search-bar {
		display: none !important;
	}
	.top-row.top-row-split .search-bar-mini {
		display: inline-block !important;
	}
}

@media only screen and (max-width: 800px) {
	.search-bar {
		display: none !important;
	}
	.search-bar-mini {
		display: inline-block !important;
	}
}

// sm
@media screen and (max-width: 768px) {
	:root {
		--top-panel-height: 79px;
		--nav-width: 0px;
	}
}

@media only screen and (max-width: 768px) and (min-width: 670px) {
	.dash-row div[class*="col-"] {
		width: 50%;
	}
}

// xs
@media screen and (max-width: 576px) {
	.top-row .btn-lg.mx-1 {
		margin: 0 1px !important;
		font-size: 1.25em;
		padding: 0.5em 0.75em;
	}
}

@media only screen and (max-width: 500px) {
	.top-left {
		flex-grow: 1 !important;
	}
}

@media only screen and (max-width: 460px) {
	.top-row.top-row-split {
		height: var(--top-panel-split-height);
	}

	.top-left {
		width: 100%;
	}

	// :root {
	// 	//--top-panel-height: var(--top-panel-split-height);
	// 	//--nav-width: 155px;
	// }
}

.squeeze {
	transition: width 0.1s cubic-bezier(0, 0.52, 0, 1),
		min-width 0.1s cubic-bezier(0, 0.52, 0, 1);
	width: var(--infopanel-width);

	&.hide {
		width: 0;
		min-width: 0;
	}

	&.show {
		width: var(--infopanel-width);
		min-width: var(--infopanel-width);
	}

	&.left {
		right: auto;
		left: 0;
	}
}

.sidebar {
	position: fixed;
	top: var(--top-panel-height);
	bottom: 0;
	left: 0;
	overflow-y: auto;
	height: calc(100% - var(--top-panel-height)) !important;
	width: var(--nav-width);
}

.content-pane {
	background-color: #f1f1f1;
	position: absolute;
	top: var(--top-panel-height);
	left: var(--nav-width);
	width: calc(100% - var(--nav-width));
	overflow-y: auto;
	height: calc(100% - var(--top-panel-height));
	padding-top: 1em;

	@media screen and (min-width: 769px) {
		&.squeezed {
			transition: width 0.3s cubic-bezier(0, 0.52, 0, 1),
				min-width 0.3s cubic-bezier(0, 0.52, 0, 1);
			width: calc(100% - (var(--nav-width) + var(--infopanel-width)));
		}
	}
}

.track-info-panel.show .BeaconFabButtonFrame {
	transform: translateX(-$info-panel-width) !important;
}

.single-form-accordion .accordion-button {
	pointer-events: none;

	&:after {
		display: none;
	}
}

.squeeze {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	top: var(--top-panel-height);
}

.boxy-blur {
	pointer-events: none;
	opacity: 0.275;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
	.glass {
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
		background-color: rgba(255, 255, 255, 0.35);
	}
}

@media only screen and (max-width: 992px) {
	.glass {
		width: 90% !important;
	}

	.svgBG.h-100 {
		margin-bottom: 0;
		height: 50vh !important;
		background-size: auto !important;
	}
}

@media only screen and (max-width: 768px) {
	.glass {
		width: 90% !important;
	}

	.svgBG.h-100 {
		margin-bottom: 0;
		background-size: cover !important;
		height: 100% !important;
	}
}

@media only screen and (max-width: 670px) {
	.glass {
		width: 90% !important;
	}

	.svgBG.h-100 {
		margin-bottom: 0;
		background-size: cover !important;
		height: 60vh !important;
	}
}
