$custom: (
	soft: #fff,
);

@each $color, $value in $custom {
	.btn-#{$color} {
		@include button-variant($value, $value);
	}
}

@each $color, $value in $custom {
	.btn-outline-#{$color} {
		@include button-outline-variant($value);
	}
}

a.badge {
	text-decoration: none;
	border: 1px transparent;
	border-bottom: solid 1px rgba(0, 0, 0, 0.25);

	&:hover {
		background-color: #0d6efd !important;
		color: #fff !important;
	}
}

.label-btn-group .btn {
	padding: 0.15rem 0.45rem;
	font-size: 0.8rem;
	border: none;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
	pointer-events: all !important;
	cursor: not-allowed;
	opacity: 0.5 !important;
}

.accordion-button div {
	font-weight: 500;
	font-size: 1.05em;
}
.accordion-button small.bg-white {
	font-size: 0.825em;
	font-family: "Roboto";
}

.primary-link {
	border: solid 1px #0d6efd;
}

.pulse {
	animation: pulse 1s 3;

	&.tenet {
		animation: pulse 1s infinite;
	}

	&:hover {
		animation: none;
	}
}
.pulse-dark {
	animation: pulse-dark 1s 3;

	&:hover {
		animation: none;
	}
}
.pulse-white {
	animation: pulse-white 1s 3;

	&:hover {
		animation: none;
	}
}
.pulse-success {
	animation: pulse-success 1s 3;

	&:hover {
		animation: none;
	}
}
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(49, 132, 253, 0.25);
	}

	50% {
		box-shadow: 0 0 0 4px rgba(49, 132, 253, 0.3);
	}

	100% {
		box-shadow: 0 0 0 3px rgba(49, 132, 253, 0.15);
	}
}

@keyframes pulse-dark {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.253);
	}

	50% {
		box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.3);
	}

	100% {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15);
	}
}

@keyframes pulse-white {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	50% {
		box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5);
	}

	100% {
		box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
	}
}

@keyframes pulse-success {
	0% {
		box-shadow: 0 0 0 0 rgba(25, 135, 84, 0.253);
	}

	50% {
		box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.3);
	}

	100% {
		box-shadow: 0 0 0 3px rgba(25, 135, 84, 0.15);
	}
}
