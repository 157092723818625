.skeleton {
	width: 100%;
	min-height: 1rem;
	display: block;
	background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.3) 50%,
			rgba(255, 255, 255, 0) 80%
		),
		#dfe7f3;
	background-repeat: repeat-y;
	background-size: 10em 500px;
	background-position: -200% 0;
	animation: shine 2s infinite;
	border-radius: 0.5rem;
}
@keyframes shine {
	to {
		background-position: 300% 0;
	}
}

.label-scrollpane,
.content-pane,
.ip-body,
.sidebar {
	&::-webkit-scrollbar {
		width: 0.75em;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
	}

	&::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		outline: 1px solid slategrey;
	}
}
