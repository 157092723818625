$tooltip-margin: 1rem;

.search-results-header .badge {
	font-size: 1em;
	font-weight: 400;
	text-align: left;
}

.options-scroll {
	position: absolute;
	display: block !important;
	line-height: 1;
	z-index: 100;
	left: auto;
	right: auto;
	width: 100%;

	.inner-scroll {
		max-height: calc(100vh - 200px);
		overflow: auto;
	}
}

.top-row .search-bar-mini {
	display: none;
}

@media only screen and (max-width: 1060px) {
	.options-scroll {
		width: max-content;
	}
}
@media only screen and (max-width: 460px) {
	.options-scroll {
		left: -10vw;
		width: 95vw;
	}
	.m-none {
		display: none;
	}
}

@media only screen and (max-width: 405px) {
	.options-scroll {
		left: -10vw;
		width: 90vw;
	}
}
