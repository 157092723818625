dl:not(.ip-content) dd {
  margin: 0;
  padding: 0.15em 0.75em;
  border-radius: 5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  &:hover {
    background-color: rgba(13, 110, 253, 0.15) !important;

    .text-muted {
      color: #0d6efd !important;
    }
  }
}
