.logo {
	margin-right: 2em;

	img {
		width: 250px;
	}
}

.search-bar {
	width: 40vw !important;
}

.h-ctas .btn {
	font-size: 25px;
	color: #5f6368;

	&:hover {
		background-color: #f6f6f6;
		color: #000;
	}

	&.active {
		background-color: #e8f0fe;
		color: $primary;
	}
}

.h-ctas .dropdown .dropdown-menu .btn {
	font-size: 1em;
	width: 100%;
}

@media only screen and (max-width: 768px) {
	.m-search-bar {
		display: inline-block;
	}

	.search-bar {
		display: none;
	}
}
