.ip-header h5 {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-size: 1.15em;
	line-height: 1.35em;
	cursor: help;
	font-family: "Oswald";
	height: max-content;
}

.infopane-body {
	overflow: auto !important;
}

.ip-btns {
	background-color: pink;
}

.infopane {
	/* for Firefox */
	min-height: 0;
}

.bg-note {
	background-image: linear-gradient(
		15deg,
		rgba(129, 161, 247, 0.35) 2%,
		#ffffff 100%
	);
}

.ip-body {
	background: white;
	overflow: auto;

	/* for Firefox */
	min-height: 0;
}

.ip-close {
	font-size: 1.5em !important;
	color: #5f6368 !important;

	&:hover {
		background-color: #f6f6f6;
		color: #000 !important;
	}
}

.ip-label {
	width: 35%;
	font-size: 0.875em;
	color: #5f6368;
}

.ip-content,
.ip-description {
	margin: 0.5em;
	font-size: 0.9em;
}

.ip-label.bold {
	font-weight: 900;
}

.ip-photo {
	max-height: 300px;
	border-radius: 10px;
	border: solid 3px transparent;

	&:hover {
		opacity: 0.875;
		border: solid 3px #000;
	}
}

.location-link {
	font-size: 0.875em;
}

.file-type {
	margin: 0 0.35em 0 0.25em;
	font-size: 2.7em;
	opacity: 0.25;
}

.label-scrollpane {
	max-height: 50vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.nav-link.disabled {
	opacity: 0.25;
	cursor: not-allowed !important;
	pointer-events: all !important;
}

.ip-image {
	background: top center no-repeat;
	height: 320px;
	background-size: contain;
	border-bottom: solid 1px #efefef;
}

.ip-link {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
}
.tab-nav {
	border-top: solid 1px #e8f0fe;

	svg {
		font-size: 1.35em !important;
	}
}
@media only screen and (max-width: 992px) {
	.ip-header h5 {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		height: max-content;
	}
	.tab-nav {
		.nav-link {
			padding-left: 0.95rem;
			padding-right: 0.95rem;
		}

		svg {
			font-size: 1.25em !important;
		}
	}
}

.nav-pills .nav-link.text-danger {
	color: lighten(#dc3545, 5%) !important;

	&:not(.active):hover {
		color: #dc3545 !important;
	}
}
