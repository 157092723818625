.preview-title {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.preview-header {
	background-color: #212529;
}

.preview-pane {
	height: calc(100% - 200px);
	display: flex;
	flex-grow: 1 !important;
	flex-direction: column !important;
}

// @media only screen and (min-width: 576px) and (max-width: 680px) {
// 	.preview-header {
// 		height: 245px;
// 	}

// 	.preview-pane {
// 		height: calc(100% - 245px);
// 	}
// }

@media only screen and (max-width: 576px) {
	.preview-header .preview-cta {
		font-size: 0.7em !important;
		padding: 0.75em 1em !important;

		svg {
			font-size: 14px !important;
		}
	}

	.preview-title {
		font-size: 0.7em !important;
	}

	.preview-header {
		.preview-logo {
			padding: 0 !important;
			margin: 0 !important;

			img {
				width: 165px !important;
			}
		}

		.preview-download {
			width: 75vw !important;

			a {
				font-size: 0.75em !important;
				padding: 0.25em 0.5em;
				width: 100% !important;
			}
		}
	}

	.btn.btn-soft.dropdown-toggle.dropdown-toggle-split {
		padding: 0 0.5em;
	}

	// .preview-header {
	// 	height: 235px;
	// }

	// .preview-pane {
	// 	height: calc(100% - 235px);
	// }
}

@media only screen and (max-width: 460px) {
	.preview-header .preview-cta svg {
		display: none;
	}
}
