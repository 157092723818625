.modal-no-padding .modal-body {
	padding: 0 !important;
}

.modal-hide-content .modal-body {
	display: none;
}

.list-group-item {
	color: #292929;
}

.icon-list {
	font-size: 1.5em;
	opacity: 0.575;
	color: $primary;
}

.list-group-item {
	&:hover {
		background-color: #f6f6f6;
		color: $primary;
		font-weight: 500;

		.icon-list {
			color: $primary !important;
			opacity: 1;
		}
	}

	&.active {
		font-weight: 500;

		.icon-list {
			color: #fff;
			opacity: 1;
		}
	}
}

.form-check-label.active {
	font-weight: 500;
}

@media screen and (max-width: 768px) {
	.logo img {
		width: 175px;
	}

	.top-row:not(.admin-spacing) {
		.search-bar {
			display: none !important;
		}

		.search-bar-mini {
			display: inline-block;
		}
	}
}

@media only screen and (max-width: 440px) {
	.logo {
		margin: 0 !important;
		position: relative;
		right: -8px;
		width: 170px;
	}
}
@media only screen and (max-width: 400px) {
	body .m-btns .btn {
		padding: 5px 7px !important;
		svg {
			width: 20px !important;
		}
	}
}

@media only screen and (max-width: 330px) {
	body .m-btns .btn {
		padding: 5px !important;
	}
}
