.file-drop-zone {
	&.drag-over {
		border-color: #005696 !important;
		background-color: #e8f0fe;
	}

	.container-fluid {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	&.drag-over .container-fluid {
		border-color: #005696;
	}
}
