.tree-node {
	padding-left: 1em;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	text-decoration: none;
	color: #292929;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;

	&:hover {
		background-color: #f6f6f6;
		color: #000;
	}

	&.active {
		background-color: #e8f0fe;
		color: $primary;
	}

	&.tn-top {
		padding: 0.75em 1em;
	}

	&.tn-sub {
		padding: 0.5em 0.15em;
		font-size: 0.875em;
	}
}

.tree-picker .tree-node {
	border-radius: 0;

	.tn-toggle svg {
		width: 1.75rem;
		height: 1.75rem;
		color: #6c757d;
		border: 0 none;
		background: transparent;
		border-radius: 50%;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

		&:hover {
			background: #e8f0fe;
			border: solid 1px $primary;
			color: $primary;
		}
	}

	.tn-link {
		width: 100%;
		padding: 0.5em;
		border: solid 1px transparent;

		&:hover {
			background-color: #e8f0fe;
			border: solid 1px $primary;
			color: $primary;
			border-radius: 5px;
		}
	}
}
