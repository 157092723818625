$tooltip-text-color: white;
$tooltip-background-color: rgba(0, 0, 0, 0.875);
$tooltip-arrow-color: rgba(0, 0, 0, 0.875);
$tooltip-margin: 0.2rem;
$tooltip-vertical-margin: 0.5rem;
$tooltip-arrow-size: 6px;
$tooltip-max-width: 220px;

.tooltip-wrapper {
	display: inline-block;
	position: relative;
}

@keyframes tooltip-fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.tooltip-tip {
	font-size: 0.725rem;
	position: absolute;
	display: block !important;
	border-radius: 0.25rem;
	color: $tooltip-text-color;
	background: $tooltip-background-color;
	line-height: 1;
	z-index: 100;

	&.center {
		left: 50%;
		transform: translateX(-50%) !important;
	}

	opacity: 0;

	&.show {
		animation: tooltip-fade 0.2s linear forwards;
	}

	&::before {
		content: " ";
		left: 50%;
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-width: $tooltip-arrow-size;
		margin-left: -$tooltip-arrow-size;
	}

	&.top {
		top: calc((100% + #{$tooltip-vertical-margin}) * -1);
		width: max-content;
		white-space: normal;

		&::before {
			top: 100%;
			border-top-color: $tooltip-arrow-color;

		}

		&:not(.center) {
			transform: translateX(0) translateY(0);

			&::before {
				left: 10px;
			}
		}
	}

	&.left {
		left: auto;
		right: calc(100% + #{$tooltip-margin});
		top: 50%;
		transform: translateX(0) translateY(-50%);
		width: max-content;

		&::before {
			left: auto;
			right: calc(#{$tooltip-arrow-size} * -2);
			top: 50%;
			transform: translateX(0) translateY(-50%);
			border-left-color: $tooltip-arrow-color;
		}
	}

	&.right {
		left: calc(100% + #{$tooltip-margin});
		top: 50%;
		transform: translateX(0) translateY(-50%);
		width: max-content;

		&::before {
			left: -$tooltip-arrow-size;
			top: 50%;
			transform: translateX(0) translateY(-50%);
			border-right-color: $tooltip-arrow-color;
		}
	}

	&.bottom {
		bottom: calc(#{$tooltip-margin} * -1);
		width: max-content;
		white-space: normal;
		transform: translateX(0) translateY(0);

		&::before {
			bottom: 100%;
			left: 10px;
			border-bottom-color: $tooltip-arrow-color;
		}
	}
}

.tooltip-inside {
	max-width: $tooltip-max-width;
	padding: $tooltip-padding-y $tooltip-padding-x;
	color: $tooltip-color;
	text-align: center;
	background-color: $tooltip-bg;
	@include border-radius($tooltip-border-radius);
}