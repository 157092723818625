$primary: #0055b8;
$btn-link-color: $primary;
$info: #68b1ff;
$warning: #efa700;
$success: #008a0a;
$danger: #ca0000;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

@import "./skeleton";
@import "./drag-and-drop";
@import "./header";
@import "./preview";
@import "./navbar";
@import "./context-menu";
@import "./inputs";
@import "./inline-modal";
@import "./item-form";
@import "./buttons";
@import "./info-pane";
@import "./items";
@import "./layouts";
@import "./lists";
@import "./search";
@import "./tbd";
@import "./tooltips";
@import "./treenode";
@import "./typography";
@import "./file-drop-zone";

body {
	font-family: "Roboto" !important;
	margin-bottom: 80px !important;
	overflow: hidden;
}

footer {
	padding: 1em 0.7em;
	font-size: 11px;
	border-top: solid 1px #ccc;

	span,
	a {
		margin-right: 2px;
	}
}

.fixed-parent {
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
}

.cursor-pointer {
	cursor: pointer;
}

.unselectable {
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.input-group:not(.has-validation) {
	& > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
	> .dropdown-toggle:nth-last-child(n + 3) {
		& > .form-control,
		> .form-select {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}

.input-group
	> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	& > .form-control,
	> .form-select {
		margin-left: -1px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.input-group > .form-floating {
	& > .form-control,
	> .form-select {
		position: relative;
		flex: 1 1 auto;
		width: 1%;
		min-width: 0;
	}
}
