.blended-input {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom-color: transparent;
	border-radius: 0;
	&:focus {
		box-shadow: none;
	}
	&:hover {
		border-bottom-color: #0d6efd;
	}
}

.form-control.blended-input .auto-complete-item:hover {
	background-color: #efefef;
}

.form-check.form-switch label,
.form-check.form-switch input {
	cursor: pointer !important;
}


