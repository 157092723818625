.fog-glass {
	background: rgba(255, 255, 255, 0.15);
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);

	&.dark {
		background: rgba(0, 0, 0, 0.475);
		-webkit-backdrop-filter: blur(0);
		backdrop-filter: blur(0);
	}
}

.inline-modal {
	z-index: 5;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.65);

	-webkit-backdrop-filter: blur(1px);
	backdrop-filter: blur(1px);
	align-items: flex-start;
	justify-content: center;
	display: flex;
	visibility: hidden;

	&.show {
		animation-name: show;
		animation-duration: 0.3s;
		visibility: visible;
	}

	&.hide {
		animation-name: hide;
		animation-duration: 0.3s;
		animation-fill-mode: forwards;
	}

	.inline-modal-dialogue {
		margin-top: 2rem;
		width: 50%;
		border-radius: 0.25rem;
		background-color: #fff;
		border: 1px solid #dee2e6;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

		&.inline-modal-dialogue-lg {
			width: 75%;
		}

		.inline-modal-body {
			padding: 0.5rem;
		}

		.inline-modal-footer {
			padding: 0.5rem;
			border-top: 1px solid #dee2e6;
		}
	}
}

@keyframes show {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes hide {
	0% {
		visibility: visible;
		opacity: 1;
	}

	100% {
		visibility: hidden;
		opacity: 0;
	}
}
