.navPane {
	width: 235px;

	&.squeeze {
		z-index: 1;
	}
}

.navList:not(.tree-li) {
	list-style: none;
	padding: 0;

	li:not(.tree-container) {
		margin: 1em 0.75em 1em 0;
		font-weight: 500;
		font-size: 0.9em;
		overflow: hidden;

		& > a {
			padding: 0.75em 1em;
			display: block;
			border-top-right-radius: 20px;
			border-bottom-right-radius: 20px;
			text-decoration: none;
			color: #292929;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&:hover {
				background-color: #f6f6f6;
				color: #000;
			}

			&.active {
				background-color: #e8f0fe;
				color: $primary;
			}
		}
	}
}

.nav-pills {
	.nav-link {
		padding: 0.5em 1em;
		border-radius: 0 !important;
		border-top: solid 3px transparent !important;
		color: #5f6368 !important;

		&.active {
			background-color: #fff !important;
			border-top: solid 3px $primary !important;
			color: $primary !important;
		}

		&:not(.active):hover {
			background-color: #f6f6f6;
			color: #000 !important;
		}
	}

	svg {
		font-size: 1.5em;
	}
}

.crumb-status {
	font-size: 10px;
	position: relative;
	top: -10px;
	right: -2px;
}
