.context-menu {
  position: absolute;
  overflow: hidden;
  z-index: 10000;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
    }
  }

  .context-menu-section-header {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .context-menu-item {
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .context-menu-icon {
      width: 2em;
      padding: 0.5rem;
    }

    .context-menu-text {
      border-left: 1px solid #dee2e6;
      padding: 0.5rem;
      white-space: nowrap;
    }
  }
}
