:root {
	--item-grid-width: calc((100vw - 235px) / 3.5);
	--squeezed-item-grid-width: calc((100vw - 555px) / 2.75);
}

@media only screen and (max-width: 1200px) {
	:root {
		--squeezed-item-grid-width: calc((100vw - 555px) / 2.25);
	}
}

@media only screen and (max-width: 1150px) {
	:root {
		--squeezed-item-grid-width: 30vw;
	}
}

@media only screen and (max-width: 1050px) {
	:root {
		--item-grid-width: calc((100vw - 235px) / 2.5);
	}
}
@media only screen and (max-width: 870px) {
	:root {
		--squeezed-item-grid-width: calc(100vw - 620px);
	}
}
@media only screen and (max-width: 768px) {
	:root {
		--item-grid-width: calc(100vw / 2.5);
	}
}

@media only screen and (max-width: 490px) {
	:root {
		--item-grid-width: 75vw;
	}
}
.grid-container {
	padding: 0;
	width: 225px;
	overflow: hidden;
	border: solid 2px transparent;
	cursor: pointer;

	&:hover {
		background-color: #f6f6f6;
		border-color: $primary !important;
		color: $primary;
		box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
	}

	&.active {
		background-color: #e8f0fe;
	}
}

.grid-image {
	padding: 0;
	margin: 0;
}

.grid-title {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-size: 0.9em;
	font-weight: 500;
	line-height: 1.35em;
	margin: 0 0.75rem;
	color: $primary;
	height: max-content;
}

.grid-icon {
	padding-top: 2px;

	svg {
		font-size: 1.35em;
	}
}

.item-grid {
	width: var(--item-grid-width);
	color: #292929;
	background-color: #fff;
	overflow: hidden;
	position: relative;
	float: left;
	display: inline-block;
	cursor: pointer;
	svg {
		font-size: 1.25em;
	}

	&:hover {
		background-color: #f6f6f6;
		color: #000;
		cursor: pointer;
		border-color: $primary !important;
	}
}

.item-grid:hover .card-img-top.with-motion,
.item-grid:focus .card-img-top.with-motion {
	-ms-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1);
	background-color: #fff !important;
}

.item-grid:hover .card-img-top.with-motion .top-details,
.item-grid:focus .card-img-top.with-motion .top-details {
	display: none !important;
}
@media screen and (min-width: 769px) {
	.squeezed .item-grid {
		width: var(--squeezed-item-grid-width);
	}
}

.grid-item-name {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-size: 0.9em;
	font-weight: 500;
	line-height: 1.5em;
	height: max-content;
}

.item-grid .card-img-top {
	background-repeat: no-repeat;
	height: 200px;
	background-size: contain;
	background-position: center;

	&.with-motion {
		min-height: 200px;
		height: 100%;
		width: 100%;
		overflow: hidden;
		background-size: contain;
		background-position: top center;
		background-repeat: no-repeat;
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-o-transition: all 0.5s;
		transition: all 0.5s;
	}
}

.active-selection {
	background-color: #e8f0fe;
	border: solid 1px $primary !important;
	color: $primary !important;
}

.drag-over .item-grid {
	border: solid 1px $primary !important;
}

.file-extension {
	font-size: 0.625rem;
	text-shadow: 0 0 black;
	text-transform: uppercase;
}

.row-vcenter td {
	vertical-align: middle;
}
.row-vcenter:not(.active-selection):hover {
	border-color: #888;
	background-color: #f6f6f6;
}
.filetype-icon {
	font-size: 2em;
}

div.promoted-enabled .promoted {
	background-image: linear-gradient(
		45deg,
		rgba(255, 235, 33, 0.25) 9%,
		#ffffff 90%
	);
	outline: solid 2px rgba(255, 235, 33, 0.65);
	//box-shadow: 0 0.25rem 0.5rem rgba(255, 235, 33, 0.35) !important;
}
