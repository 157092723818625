h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Oswald";
}
.sub-header {
	font-family: "Oswald";
	font-weight: 300;
	letter-spacing: 0.75px;
}
.subhead {
	text-transform: uppercase;
	font-size: 0.85em;
	letter-spacing: 1.05px;
	border-bottom: solid 1px #5f6368;
	padding-bottom: 0.25em;
}
.text-increase {
	font-size: 1.75em !important;
}
.bg-medium {
	background-color: #e9ecef;
}

.fa-opaque {
	opacity: 0.25;
	color: #000;
}

.link {
	color: $primary;
	text-decoration: underline;
	cursor: pointer;

	&:hover {
		color: shade-color($primary, $btn-hover-bg-shade-amount);
	}
}

.inline-stack {
	position: relative;
	top: 0;
	left: 0;
}

.inline-stack-1 {
	position: relative;
	top: 0;
	left: -22px;
	margin-right: -22px;
}

.inline-stack-2 {
	position: relative;
	top: 0;
	left: -20px;
	margin-right: -25px;
}
