dl dd.unable {
	cursor: not-allowed;
}

body dl dd.unable:hover {
	background-color: #f8f9fa !important;

	.text-muted {
		color: #6c757d !important;
	}
}

.auto-complete-item:hover {
	background-color: #0d6efd;
	color: #fff;
}

.form-label {
	font-weight: 500;
	font-size: 0.9em;
}

.required svg {
	font-size: 0.75em;
	position: relative;
	top: -5px;
	color: #dc3545;
	margin-right: 0.25em;
}

.uploaded td {
	background-color: rgba(25, 135, 84, 0.05) !important;
	color: #333 !important;

	svg {
		color: rgb(25, 135, 84) !important;
	}
}

.editing {
	border: solid 1px #0d6efd !important;
	border-top: solid 2px #0d6efd !important;

	td {
		padding-top: 1em !important;
		padding-bottom: 1em !important;
	}
}

.bulk-error {
	border: solid 1px #dc3545 !important;
	border-top: solid 2px #dc3545 !important;

	td {
		padding-top: 1em !important;
		padding-bottom: 1em !important;
		background-color: #fbeff0 !important;
	}
}

.skipped td {
	background-color: #efefef !important;
	color: #888 !important;
}

.d-badge {
	font-size: 0.75em;
	font-weight: 700;
}
